import React from 'react';
import {Button} from '@material-ui/core';
import TransferSendForm from './TransferSend';
import TransferGetForm from './TransferGet';
import WithTranslator from '../../providers/WithTranslator';
import WithTransferMode from '../../providers/WithTransferMode';

import './TransferPart.scss';

class TransferPart extends React.Component {
    render() {
        return (
            <div className='transfer-part' id='transferPart'>
                <div className='transfer-part__inner'>
                    {this.props.transferMode !== 1
                        ? <TransferSendForm/>
                        : <TransferGetForm/>
                    }

                </div>
            </div>
        )
    }
}

export default WithTransferMode(WithTranslator(TransferPart));
