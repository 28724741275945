import React from 'react';
import {Button} from '@material-ui/core';
import TextInput from '../textInpit/TextInput';
import WithTranslator from '../../providers/WithTranslator';
import {RESULT_STATUS as STATUS} from '../../constants';

import {ReactComponent as Success} from '../../resources/success.svg';
import {ReactComponent as Warning} from '../../resources/warning.svg';
import Loader from "../loader/Loader";
import {ReactComponent as IconBack} from "../../resources/icons/icon-back.svg";
import WithTransferMode from "../../providers/WithTransferMode";

class TransferResultStep extends React.Component {
    render() {
        const {translator, status} = this.props;
        return (
            <>
                {status !== STATUS.WAIT &&
                <button className='back-link' onClick={() => this.props.setTransferMode(-1)}>
                    <IconBack/>
                    <span>{translator('backLink.toMain')}</span>
                </button>
                }
                <div className='finish-step' id='transferResult'>
                    <span className='header'>{translator(`transferPart.result.${status}.title`)}</span>
                    {status === STATUS.WAIT ?
                        <>
                            <Loader/>
                        </>
                        : status === STATUS.SUCCESS ?
                            <Success/>
                            : status === STATUS.ERROR
                                ?
                                <Warning/>
                                :
                                <span className='code'>
                                <TextInput value={this.props.trId} disabled withCopy
                                           label={translator(`transferPart.result.${status}.title`)}/>

                                {/*<TransferReport {...this.props}/>*/}
                            </span>

                    }

                    {status !== STATUS.CODE && translator(`transferPart.result.${status}.description`)}

                    {status !== STATUS.WAIT &&
                    <Button className={`primary submit`} onClick={this.props.onSubmit}>
                        {translator(`transferPart.result.${status}.submit`)}
                    </Button>
                    }
                </div>
            </>
        )
    }

}

export default WithTransferMode(WithTranslator(TransferResultStep));
