import FValidator from './FieldsValidator';
const CURRENT_YEAR = new Date().getFullYear() % 100;

const getFormattedAmount = value => {
    const parts = value.toString().split('.');
    return value ? (parts[0] || '0') + '.' + (parts[1]?.padEnd(2, '0') || '00') : '';
}

export default class FieldsHelper {
    constructor(storage) {
        this.self = storage;
    }

    setField = (name, value, error = false) =>
        this.self.setState({
            fields: {...this.self.state.fields, [name]: value},
            errors: {...this.self.state.errors, [`${name}Error`]: !!error}
        });

    setError = (name, value) =>
        this.self.setState({
            errors: {...this.self.state.errors, [`${name}Error`]: !!value}
        });

    onCodeChange = e => {
        let code = e.currentTarget.value;
        this.setField('code', code);
    }

    onAmountChange = e => {
        let amount = e.currentTarget.value;
        if (/^\d*.?\d{0,2}$/.test(amount) && +amount <= configs.amount[configs.currency].max)
            this.setField('amount', amount);
    }

    onAmountBlur = e => {
        const amount = getFormattedAmount(this.self.state.fields.amount);
        this.self.setState({fields: {...this.self.state.fields, amount}});
    }

    onCurrencyChange = e => {
        let currency = e.target.value;
        configs.currency = currency;
        let amount = Math.min(+this.self.state.fields.amount, configs.amount[configs.currency].max);
        amount = getFormattedAmount(amount);
        const state = {
            fields: {...this.self.state.fields, currency, amount},
            errors: {...this.self.state.errors, 'amountError': false}
        };
        this.self.setState({...state});
    }

    onCardChange = (e, name) => {
        name = name || 'card';
        let card = e.currentTarget.value;
        if (!card) {
            this.setField(name, card);
            return;
        }

        const cardValue = card.replace(/\s/g, '');
        const cardValues = cardValue.match(/\d{1,4}/g);
        if (cardValue.length <= 19 && cardValues?.every(i => !isNaN(+i))) {
            this.setField(name, cardValues.join(' '));
        }
    }

    onCardBlur = (e, name) => {
        name = name || 'card';
        let v = e.currentTarget.value;
        this.setError(name, v && !FValidator.isCardValid(v));
    }

    onOwnerChange = e => {
        let owner = e.currentTarget.value.toUpperCase();
        if (!owner || /^[A-Z]+[ ]?[A-Z]*$/.test(owner))
            this.setField('owner', owner);
    }

    onDateChange = (e, name) => {
        name = name || 'date';
        let date = e.currentTarget.value;
        if (!date) {
            this.setField(name, date);
            return;
        }
        if (!/^\d{0,2}\/?\d{0,2}$/.test(date))
            return;

        let [month, ...year] = date.match(/\d{1,2}/g);
        year = year.join('');
        const monthValid = !month || (/^\d{0,2}$/.test(month) && (+month > -1 && +month < 13));
        const yearValid = !year || /^\d{0,2}$/.test(year);
        if (monthValid && yearValid) {
            year && (date = [month, year].join('/'));
            this.setField(name, date);
        }
    }

    onDateBlur = (e, name) => {
        name = name || 'date';
        let date = e.currentTarget.value;
        let [month, year] = date.split('/');
        year = (year || '').padStart(2, '0');
        month = month.padStart(2, '0');
        const v = date ? [month, year].join('/') : '';
        this.setField(name, v, v && !FValidator.isDateValid(v));
    }

    onMonthChange = e => {
        let month = e.currentTarget.value;
        if (/^\d{0,2}$/.test(month) && ((+month > -1 && +month < 13) || month === ''))
            this.setField('month', month);
    }

    onMonthBlur = e => {
        const month = this.self.state.fields.month;
        month !== '' && this.self.setState({
            fields: {...this.self.state.fields, month: month.padStart(2, '0')}
        });
    }

    onYearChange = e => {
        let year = e.currentTarget.value;
        if (!/^\d{0,2}$/.test(year))
            return;
        if (+year < 10 || +year >= CURRENT_YEAR)
            this.setField('year', year);
    }

    onCvvChange = e => {
        let cvv = e.currentTarget.value;
        if (/^\d{0,3}$/.test(cvv))
            this.setField('cvv', cvv);
    }

    onCvvBlur = e => {
        let v = e.currentTarget.value;
        this.setError('cvv', !FValidator.isCvvValid(v));
    }
}