import React from 'react';
import {Button, Checkbox, Paper} from '@material-ui/core';
import TransferCardForm from './TransferCardForm';
import TextInput from '../textInpit/TextInput';
import WithTranslator from '../../providers/WithTranslator';
import FieldsHelper from './FieldsHelper';
import FValidator from './FieldsValidator';
import Tooltip from "../tooltip/Tooltip";

import {ReactComponent as IconBack} from '../../resources/icons/icon-back.svg';
import WithTransferMode from "../../providers/WithTransferMode";
import TransferIcons from "./TransferIcons";


const defaultFields = {
    currency: configs.currency,
    amount: '',
    card: '',
    owner: '',
    date: '',
    cvv: '',
    agree: false,
    deferPayout: false
};

class TransferSendFirstStep extends React.Component {
    constructor(props) {
        super(props);

        this.FHelper = new FieldsHelper(this);
        const fields = this.props.fields ? {...this.props.fields} : {...defaultFields};
        this.state = {
            fields,
            errors: {}
        };
    }

    onSubmit = () => {
        const errors = {
            amountError: !FValidator.isAmountValid(this.state.fields.amount),
            cardError: !FValidator.isCardValid(this.state.fields.card),
            ownerError: !FValidator.isOwnerValid(this.state.fields.owner),
            dateError: !FValidator.isDateValid(this.state.fields.date),
            cvvError: !FValidator.isCvvValid(this.state.fields.cvv),
            agreeError: !this.state.fields.agree,
        };
        if (Object.values(errors).some(i => !!i)) {
            this.setState({errors: {...errors}});
        } else {
            this.props.onSubmit({...this.state.fields});
        }
    }

    onCancel = () => {
        this.setState({fields: {...defaultFields}, errors: {}});
        this.props.onCancel();
    }

    render() {
        const translator = this.props.translator;
        const commission = this.state.fields.amount * 0.025;
        return (
            <>
                <button className='back-link' onClick={() => this.props.setTransferMode(-1)}>
                    <IconBack/>
                    <span>{translator('backLink.toMain')}</span>
                </button>
                <div>
                    <span className='header'>{translator('transferPart.menu.btn.makeTransfer')}</span>
                    <TransferIcons/>

                    <div className='cards'>

                        <TransferCardForm storage={this}/>

                        <Paper className='card df_fdc_jcsb'>
                            <span className='h3'>{translator('transferPart.form.amount.title')}</span>

                            <div className='margin-bottom inline-fields'>
                                <TextInput label={translator('transferPart.form.amount.placeholder')}
                                           placeholder='10 000'
                                           value={this.state.fields.amount} onChange={this.FHelper.onAmountChange}
                                           error={this.state.errors.amountError}
                                           onBlur={this.FHelper.onAmountBlur}/>

                                <TextInput label={translator('transferPart.form.currency.placeholder')}
                                           select
                                           disabled
                                           SelectProps={{MenuProps: {disableScrollLock: true}}}
                                           value={this.state.fields.currency}
                                           onChange={this.FHelper.onCurrencyChange}
                                           onBlur={this.FHelper.onAmountBlur}>
                                    {['kzt'].map(value => (
                                        <option key={value} value={value}>
                                            {translator(`transferPart.form.currency.${value}`)}
                                        </option>
                                    ))}
                                </TextInput>
                            </div>

                            <div className='margin-bottom-auto'>
                                {translator('transferPart.form.commission2')}
                                <span className='_primary'>
                                    {commission.toFixed(2)}
                                    {" ₸"}
                                </span>
                            </div>

                            <div className='agree-part margin-bottom'>
                                <Checkbox checked={this.state.fields.deferPayout}
                                          onChange={() => this.setState({
                                              fields: {
                                                  ...this.state.fields,
                                                  deferPayout: !this.state.fields.deferPayout
                                              }
                                          })}/>
                                <span className='margin-right _contents'>{translator('transferPart.form.code')}</span>
                                <Tooltip title={translator('transferPart.form.code.tooltip')}
                                         placement={'top'}
                                         leaveTouchDelay={5000}
                                         enterTouchDelay={10}>
                                    <a src=''>{translator('transferPart.form.code.description')}</a>
                                </Tooltip>
                            </div>

                            <div className='agree-part'>
                                <Checkbox checked={this.state.fields.agree}
                                          onChange={() =>
                                              this.setState({
                                                  fields: {
                                                      ...this.state.fields,
                                                      agree: !this.state.fields.agree
                                                  }
                                              })
                                          }/>
                                <span>
                            {translator('transferPart.form.agree1')}&nbsp;
                                    <a href={`/terms-paymajor-${this.props.language}.pdf`} target='_blank'>
                                {translator('transferPart.form.agree2')}
                            </a>
                        </span>
                            </div>
                        </Paper>

                    </div>


                </div>


                <Button className={`primary submit`} onClick={this.onSubmit}
                        disabled={
                            !Object.entries(this.state.fields).every(([k, v]) => !!v || k === 'deferPayout')
                            || Object.values(this.state.errors).some(e => !!e)
                        }>
                    {translator('transferPart.form.btn1.submit')}
                </Button>
            </>
        )
    }
}

export default WithTransferMode(WithTranslator(TransferSendFirstStep));
