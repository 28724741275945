import React from 'react';
import WithTranslator from '../../providers/WithTranslator';
import ReactHtmlParser from "react-html-parser";

import './AdvantagesPart.scss';
import { ReactComponent as Icon1} from '../../resources/advantages1.svg';
import { ReactComponent as Icon2} from '../../resources/advantages2.svg';
import { ReactComponent as Icon3} from '../../resources/advantages3.svg';

class AdvantagesPart extends React.Component {
    render() {
        const translator = this.props.translator;
        return (
            <div className='advantages-part' id='advantagesPart'>
                <span className='h2 _primary _center'>{translator('advantagesPart.title')}</span>
                <div className='container'>
                    {[Icon1, Icon2, Icon3].map((Icon, i) =>
                        <div key={i} className='item'>
                            <Icon/>
                            <span className='h3 _primary'>{translator(`advantagesPart.title${i}`)}</span>
                            <span>{ReactHtmlParser(translator(`advantagesPart.text${i}`))}</span>
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default WithTranslator(AdvantagesPart);