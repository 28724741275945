import React from 'react';
import {Button, Fab, Modal} from '@material-ui/core';
import TextInput from '../textInpit/TextInput';
import WithTranslator from '../../providers/WithTranslator';
import apiService from '../../services/apiService';

import './ContactsPart.scss';
import {ReactComponent as Success} from '../../resources/success.svg';
import {ReactComponent as Warning} from '../../resources/warning.svg';
import Loader from "../loader/Loader";
import {ReactComponent as IconCancel} from "../../resources/icons/icon-cancel.svg";
import WithTransferMode from "../../providers/WithTransferMode";

const DEFAULT_FIELDS = {
    email: '',
    name: '',
    transfer: '',
    message: '',
}

const STATUS = {
    FORM: 'form',
    WAIT: 'wait',
    SUCCESS: 'success',
    ERROR: 'warning'
}

class ContactsPart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fields: {...DEFAULT_FIELDS},
            errors: {
                email: false,
                name: false,
                transfer: false,
                message: false,
            },
            status: STATUS.FORM,
        }
    }

    updateFields = (name, value) =>
        this.setState({fields: {...this.state.fields, [name]: value}, errors: {...this.state.errors, [name]: false}});

    onEmailChange = e => {
        let email = e.currentTarget.value;
        if (/^[\w-\.]*@?[\w-\.]*$/.test(email))
            this.updateFields('email', email);
    }

    onSubmit = () => {
        const errors = {
            email: !/^[\w-\.]+@([\w-]+\.)+[\w-]+$/.test(this.state.fields.email),
            name: !this.state.fields.name,
            transfer: !this.state.fields.transfer,
            message: !this.state.fields.message,
        };
        if (Object.values(errors).some(i => !!i)) {
            this.setState({errors: {...errors}});
        } else {
            this.setState({status: STATUS.WAIT});
            const {name, email, transfer, message} = this.state.fields;
            apiService.sendFeedback({
                replyTo: `${name} <${email}>`,
                subject: this.props.translator('contactsPart.email.subject')(transfer),
                message: this.props.translator('contactsPart.email.message')(name, email, transfer, message)
            }).then(() => this.setState({status: STATUS.SUCCESS}))
                .catch(() => this.setState({status: STATUS.ERROR}))
        }
    }

    onStart = () => {
        const state = this.state.status === STATUS.SUCCESS
            ? {status: STATUS.FORM, fields: {...DEFAULT_FIELDS}}
            : {status: STATUS.FORM};
        this.setState(state);
    }

    onClose = () => {
        this.props.closeFeedback();
        this.setState({status: STATUS.FORM, fields: {...DEFAULT_FIELDS}});
    }

    render() {
        const translator = this.props.translator;
        return (
            <Modal
                open={this.props.open}
                className='contacts-part'
            >
                <form>
                    <div className='header'>
                        <span className='h3'>{translator(`contactsPart.${this.state.status}.title`)}</span>
                        {this.state.status !== STATUS.WAIT &&
                        <Fab onClick={this.onClose} className='close-btn' disableRipple>
                            <IconCancel/>
                        </Fab>
                        }
                    </div>
                    {this.state.status === STATUS.FORM
                        ?
                        <div>
                            <div className='inline-fields margin-bottom'>
                                <TextInput label={translator('contactsPart.form.email')}
                                           className='margin-bottom'
                                           placeholder='your_mail@gmail.com'
                                           type='email'
                                           value={this.state.fields.email} onChange={this.onEmailChange}
                                           error={this.state.errors.email}/>
                                <TextInput label={translator('contactsPart.form.name')}
                                           placeholder={translator('contactsPart.form.name.placeholder')}
                                           value={this.state.fields.name} error={this.state.errors.name}
                                           onChange={e => this.updateFields('name', e.currentTarget.value)}/>
                            </div>
                            <TextInput label={translator('contactsPart.form.transaction')}
                                       placeholder='12345'
                                       className='margin-bottom'
                                       value={this.state.fields.transfer} error={this.state.errors.transfer}
                                       onChange={e => this.updateFields('transfer', e.currentTarget.value)}/>
                            <TextInput label={translator('contactsPart.form.message')}
                                       placeholder={translator('contactsPart.form.message.placeholder')}
                                       className='message margin-bottom' multiline rowsMax={5} rows={4}
                                       value={this.state.fields.message} error={this.state.errors.message}
                                       onChange={e => this.updateFields('message', e.currentTarget.value)}/>
                            <div className='button-container'>
                                <Button className='primary' onClick={this.onSubmit}
                                        disabled={!Object.values(this.state.fields).every(f => !!f) ||
                                        Object.values(this.state.errors).some(e => !!e)}>
                                    {translator('contactsPart.form.btn.submit')}
                                </Button>
                            </div>
                        </div>
                        :
                        <div className='success-part'>
                            {this.state.status === STATUS.WAIT
                                ?
                                <Loader/>
                                : this.state.status === STATUS.SUCCESS
                                    ?
                                    <Success/>
                                    :
                                    <>
                                        <Warning/>
                                        <Button className='primary' onClick={this.onStart}>
                                            {translator('contactsPart.warning.btn')}
                                        </Button>
                                    </>
                            }
                        </div>


                    }
                </form>
            </Modal>
        )
    }
}

export default WithTransferMode(WithTranslator(ContactsPart));