import React from 'react';
import {AppBar, Button} from '@material-ui/core';
import LanguageControl from './LanguageControl';
import WithTranslator from '../../providers/WithTranslator';
import WithTransferMode from "../../providers/WithTransferMode";

import './Header.scss';
import {ReactComponent as Logo} from "../../resources/logo.svg";

class HeaderAppBar extends React.Component {
    render() {
        const {translator, goTo} = this.props;
        return (
            <>
                <AppBar position='fixed' className={`appbar`} id='header'>
                    <div className='logo-container'>
                        <Logo/>
                    </div>

                    <div className='menu'>
                        <a className={this.props.mainPartVisible ? '_active' : ''}
                           onClick={() => goTo(this.props.mainPart)}>
                            {translator('header.link.main')}
                        </a>
                        <a className={this.props.advantagesPartVisible ? '_active' : ''}
                           onClick={() => goTo(this.props.advantagesPart)}>
                            {translator('header.link.advantagesPart')}
                        </a>
                        <a className={this.props.about1PartVisible ? '_active' : ''}
                           onClick={() => goTo(this.props.about1Part)}>
                            {translator('header.link.about1')}
                        </a>
                        <a className={this.props.about2PartVisible ? '_active' : ''}
                           onClick={() => goTo(this.props.about2Part)}>
                            {translator('header.link.about2')}
                        </a>
                        <a className={this.props.commissionPartVisible ? '_active' : ''}
                           onClick={() => goTo(this.props.commissionPart)}>
                            {translator('header.link.commission')}
                        </a>
                    </div>

                    <LanguageControl/>
                </AppBar>
            </>
        )
    }
}

export default WithTransferMode(WithTranslator(HeaderAppBar));
