import React from 'react';
import {Button, Paper} from '@material-ui/core';
import WithTranslator from '../../providers/WithTranslator';
import TransferReport from './TransferReport';
import FValidator from './FieldsValidator';
import TextInput from "../textInpit/TextInput";
import FieldsHelper from "./FieldsHelper";
import {ReactComponent as IconBack} from "../../resources/icons/icon-back.svg";
import TransferIcons from "./TransferIcons";

const defaultFields = {
    card: '',
    date: '',
    deferPayout: false,
};

class TransferSendSecondStep extends React.Component {
    constructor(props) {
        super(props);

        this.FHelper = new FieldsHelper(this);
        const fields = this.props.fields ? {...this.props.fields} : {...defaultFields};
        this.state = {
            fields,
            errors: {}

        };
    }

    onSubmit = () => {
        const errors = {
            cardError: !this.state.fields.deferPayout && !FValidator.isCardValid(this.state.fields.card),
            dateError: !this.state.fields.deferPayout && !FValidator.isDateValid(this.state.fields.date),
        };
        if (Object.values(errors).some(i => !!i)) {
            this.setState({errors: {...errors}});
        } else {
            this.props.onSubmit({...this.state.fields});
        }
    }

    disabledSubmit = errors => {
        const deferPayout = this.state.fields.deferPayout;
        return !Object.entries(this.state.fields)
                .every(([k, v]) => !!v || k === 'deferPayout' || deferPayout)
            || Object.entries(errors || this.state.errors)
                .some(([k, v]) => !!v && !(['cardError', 'dateError'].includes(k) && deferPayout));
    }

    render() {
        const translator = this.props.translator;
        return (
            <>
                <button className='back-link' onClick={this.props.onCancel}>
                    <IconBack/>
                    <span>{translator('backLink.back')}</span>
                </button>
                <div>
                    <span className='header'>{translator('transferPart.menu.btn.makeTransfer')}</span>
                    <TransferIcons/>

                    <div className='cards'>
                        <Paper className='card'>
                            <span className='h3'>{translator('transferPart.form.card2.title')}</span>
                            <div className='inline-fields margin-bottom'>
                                <TextInput label={translator('transferPart.form.card2.placeholder')}
                                           className={`_big ${this.state.fields.deferPayout ? '_disabled' : ''}`}
                                           disabled={this.state.fields.deferPayout}
                                           placeholder='xxxx xxxx xxxx xxxx'
                                           error={!this.state.fields.deferPayout && this.state.errors.cardError}
                                           value={!this.state.fields.deferPayout ? this.state.fields.card : ''}
                                           onChange={this.FHelper.onCardChange}
                                           onBlur={this.FHelper.onCardBlur}/>
                                <TextInput label={translator('transferPart.form.date.placeholder')}
                                           className={`_small ${this.state.fields.deferPayout ? '_disabled' : ''}`}
                                           disabled={this.state.fields.deferPayout}
                                           placeholder='11/25'
                                           error={!this.state.fields.deferPayout && this.state.errors.dateError}
                                           value={!this.state.fields.deferPayout ? this.state.fields.date : ''}
                                           onBlur={this.FHelper.onDateBlur}
                                           onChange={this.FHelper.onDateChange}/>
                            </div>
                        </Paper>

                        <Paper className='card'>
                            <span className='h3'>{translator('transferPart.form.amount.title')}</span>
                            <TransferReport {...this.props}/>
                        </Paper>
                    </div>
                </div>


                <Button className={`primary submit`} onClick={this.onSubmit}
                        disabled={this.disabledSubmit()}>
                    {translator('transferPart.form.btn2.submit')}
                </Button>
            </>
        )
    }
}

export default WithTranslator(TransferSendSecondStep);
