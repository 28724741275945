import React from 'react';
import HeaderAppBar from './HeaderAppBar';
import HeaderMobileMenu from './HeaderMobileMenu';
import WithTranslator from '../../providers/WithTranslator';

import './Header.scss';
import WithTransferMode from "../../providers/WithTransferMode";

const defaultVisible = {
    mainPartVisible: false,
    advantagesPartVisible: false,
    about1PartVisible: false,
    about2PartVisible: false,
    commissionPartVisible: false,
};

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mainPartVisible: true
        }
    }

    componentDidMount() {
        this.parts = {
            mainPart: document.getElementById('mainPart'),
            advantagesPart: document.getElementById('advantagesPart'),
            about1Part: document.getElementById('about1Part'),
            about2Part: document.getElementById('about2Part'),
            commissionPart: document.getElementById('commissionPart'),
        }

        window.addEventListener('scroll', this.onScroll, {passive: true});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.transferMode === -1 && this.props.transferMode !== -1) {
            this.setState({...defaultVisible});
        }
    }

    goTo = element => {
        this.props.setTransferMode(-1);
        setTimeout(() =>
                window.scrollTo({
                    top: element.offsetParent?.offsetTop + element.offsetTop - 67,
                    behavior: 'smooth'
                })
            , 100);
    }

    onScroll = () => {
        let visible = {...defaultVisible};

        if (this.props.transferMode === -1) {
            let maxShift = 0;
            Object.entries(this.parts).forEach(([name, element]) => {
                const position = element.getBoundingClientRect();
                if (position.top >= 0 && position.bottom <= window.innerHeight) {
                    maxShift = Infinity;
                    visible = {...defaultVisible, [`${name}Visible`]: true};
                } else if (position.top < window.innerHeight && position.bottom >= 0) {
                    const height = position.top > 0
                        ? window.innerHeight - position.top
                        : position.bottom;
                    if (height >= maxShift) {
                        maxShift = height;
                        visible = {...defaultVisible, [`${name}Visible`]: true};
                    }
                }
            });
        }
        const fixed = window.pageYOffset > 70;
        this.setState({...visible, fixed});
    }

    render() {
        const props = {
            goTo: this.goTo,
            ...this.parts,
            ...this.state,
        }
        return (
            <>
                <HeaderAppBar {...props}/>
                <HeaderMobileMenu {...props}/>
            </>
        )
    }
}

export default WithTransferMode(WithTranslator(Header));
