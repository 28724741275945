import React from 'react';
import TextField from '@material-ui/core/TextField';
import WithTranslator from '../../providers/WithTranslator';

import './TextInput.scss';

class TextInput extends React.Component {

    handleCopy = () => navigator.clipboard.writeText(this.props.value);

    handleInsert = () => navigator.clipboard.readText()
        .then(value => this.props.onChange({target: {value}, currentTarget: {value}}));

    render() {
        const {
            withCopy, withInsert, label, className, translator,
            setLanguage, children, ...textFieldProps
        } = this.props;
        return (
            <div className={`text-input-container ${className || ''} ${textFieldProps.error ? '_error' : ''}`}>
                <span className='label'>{label}</span>
                <div className={`text-input`}>
                    <TextField
                        variant='outlined'
                        fullWidth
                        InputLabelProps={{className: 'label'}}

                        {...textFieldProps}
                        className={textFieldProps.label ? '' : 'without-tooltip'}
                    >
                        {children}
                    </TextField>
                    {withCopy
                        ?
                        <a onClick={this.handleCopy}>{translator('textInput.copy')}</a>
                        : ''}
                    {withInsert
                        ?
                        <a onClick={this.handleInsert}>{translator('textInput.insert')}</a>
                        : ''}
                </div>
            </div>
        )
    }
}

export default WithTranslator(TextInput);