export default {
    'header.link.main': 'Аударым',
    'header.link.advantagesPart': 'Артықшылықтар',
    'header.link.about1': 'Жіберушіге',
    'header.link.about2': 'Алушыға',
    'header.link.commission': 'Шарттар',
    'header.lang.ru': 'RU',
    'header.lang.kz': 'KZ',

    'mainPart.title': 'Ақша аударымдары<br/>картадан картаға',
    'mainPart.btn.makeTransfer': 'Аударым жіберу',
    'mainPart.btn.receiveTransfer': 'Аударымды алу',

    'aboutPart.title1': 'Аударымды қалай жүргізуге болады',
    'aboutPart.title2': 'Мен алушымын, ақшаны мен қалай ала аламын?',
    'aboutPart.card1.title': 'Егер мен алушының картасын білетін болсам',
    'aboutPart.card2.title': 'Егер мен алушының картасын білмейтін болсам',
    'aboutPart.card3.title': 'Егер жіберуші менің картамның деректерін енгізген болса',
    'aboutPart.card4.title': 'Егер жіберуші маған аударымның кодын жіберген болса',
    'aboutPart.card1.text1': "Аударым сомасын енгізіңіз",
    'aboutPart.card1.text2': "Өз картаңыздың деректерін енгізіңіз",
    'aboutPart.card1.text3': "Оферта талаптарымен келісу",
    'aboutPart.card1.text4': "«Жалғастыру» басыңыз",
    'aboutPart.card1.text5': "Ақша алушының картасының нөмірін және оның қолдану мерзімінің аяқталу күнін енгізіңіз",
    'aboutPart.card1.text6': "Дайын!",
    'aboutPart.card2.text1': "Аударым сомасын енгізіңіз",
    'aboutPart.card2.text2': "Өз картаңыздың деректерін енгізіңіз",
    'aboutPart.card2.text3': "Оферта талаптарымен келісу",
    'aboutPart.card2.text4': "«Жалғастыру» басыңыз",
    'aboutPart.card2.text5': "Аударым кодын алыңыз",
    'aboutPart.card2.text6': "Аударым кодын көшіріп алу және алушыға жіберу керек",
    'aboutPart.card2.text7': "Дайын!",
    'aboutPart.card3.text': 'Тек аударым өткенше күту керек! <br/> Алушының картасына қаражатты енгізу мерзімі алушының картасын шығарған банкіге байланысты болады және бірнеше минуттан бастап бірнеше күнге құрауы мүмкін',
    'aboutPart.card4.text1': "Аударымды алу қойындысына өтіңіз",
    'aboutPart.card4.text2': "Аударым кодын қойыңыз",
    'aboutPart.card4.text3': "«Алу» басыңыз",
    'aboutPart.card4.text4': "Дайын!",

    'transferPart.title': 'Аударым',
    'transferPart.form.card1.title': 'Жіберушінің картасы',
    'transferPart.form.card2.title': 'Алушының картасы',
    'transferPart.form.amount.title': 'Сомасы',
    'transferPart.menu.btn.makeTransfer': 'Аударым жүргізу',
    'transferPart.menu.btn.receiveTransfer': 'Аударымды алу',
    'transferPart.form.currency.rub': '₽ Рубль',
    'transferPart.form.currency.usd': '$ Доллар',
    'transferPart.form.currency.eur': '€ Еуро',
    'transferPart.form.currency.kzt': '₸ Тенге',
    'transferPart.form.amount.placeholder': 'Аударым сомасы',
    'transferPart.form.currency.placeholder': 'Валюта',
    'transferPart.form.card1.placeholder': 'Жіберушінің карта нөмірі',
    'transferPart.form.card2.placeholder': 'Алушының карта нөмірі',
    'transferPart.form.owner.placeholder': 'Картаны ұстағыш',
    'transferPart.form.date.placeholder': 'Айы/Жылы',
    'transferPart.form.cvv.placeholder': 'CVV',
    'transferPart.form.commission1': 'Аударым сомасы ',
    'transferPart.form.commission2': 'Комиссия құрайды ',
    'transferPart.form.commission3': 'БАРЛЫҒЫ ',
    'transferPart.form.agree1': 'Мен талаптармен келісемін',
    'transferPart.form.agree2': 'Қосылу шарты',
    'transferPart.form.code': 'Аударым кодын алыңыз',
    'transferPart.form.code.description': 'Бұл не?',
    'transferPart.form.code.tooltip': 'Егер сіз алушының карта деректерін білмесеңіз, сізге аударым кодын алуыңызға болады. Кодты алушыға жібергеннен кейін ол ақшаны өзі алатын картаны енгізеді',
    'transferPart.form.btn1.submit': 'Жалғастыру',
    'transferPart.form.btn1.cancel': 'Түсіру',
    'transferPart.form.btn2.submit': 'Аудару',
    'transferPart.form.btn2.cancel': 'Артқа',
    'transferPart.form.get.submit': 'Алу',
    'transferPart.result.wait.title': 'Транзакцияны тексеру',
    'transferPart.result.wait.description': 'Әдетте бұл бірнеше секунд уақыт алады, бетті жаппаңыз және жаңартпаңыз',
    'transferPart.result.code.title': 'Аударым коды',
    'transferPart.result.code.description': 'Кодты көшіріп алыңыз және оны алушыға жіберіңіз',
    'transferPart.result.code.submit': 'Жаңа аударым',
    'transferPart.result.success.title': 'Сіздің қаражатының жолда',
    'transferPart.result.success.description': 'Сұранысты өңдеу табысты түрде өтті',
    'transferPart.result.success.submit': 'Жаңа аударым',
    'transferPart.result.warning.title': 'Бір жерде қате бар',
    'transferPart.result.warning.description': 'Сұраныс өтпеді, бірақ тағы бір рет қайталап байқауға болады',
    'transferPart.result.warning.submit': 'Тағы бір рет қайталау',

    'commissionPart.title': 'Шарттар',
    'commissionPart.commission': 'Комиссия:',
    'commissionPart.limit': 'Лимит:',
    'commissionPart.title11': 'Ішкі аударымдар',
    'commissionPart.title12': 'Қазақстан Республикасы банкілерінің карталары арасында',
    'commissionPart.title21': 'Трансшекаралық аударымдар',
    'commissionPart.title22': 'РФ картасынан шетел карталарға',
    'commissionPart.commission1': '2,5%',
    'commissionPart.commission2': '2%',
    'commissionPart.limit1': 'Минимум 50 ₸',
    'commissionPart.limit2': 'Минимум 200 ₸',
    'commissionPart.body2': "Жақын арада Іске қосу",
    'commissionPart.warning.title': 'Мерзімдер',
    'commissionPart.warning': 'Алушының картасына қаражатты енгізу мерзімі алушының картасын шығарған банкіге байланысты болады және бірнеше минуттан бастап бірнеше күнге құрауы мүмкін',

    'advantagesPart.title': 'Артықшылықтар',
    'advantagesPart.title0': 'Алушының картасынсыз',
    'advantagesPart.text0': 'Алушының картасын,<br/>ақшаны аударуға болады',
    'advantagesPart.title1': 'Басқа банкілер',
    'advantagesPart.text1': 'Басқа банкінің төлеу картасына<br/>қаражат толтыру',
    'advantagesPart.title2': 'Белгіленген баға',
    'advantagesPart.text2': 'Жіберу алдында біз аударым сомасын<br/>және комиссияны көрсетеміз',

    'contactsPart.form.title': 'Техникалық қолдау',
    'contactsPart.form.email': 'Email',
    'contactsPart.form.name': 'Аты',
    'contactsPart.form.name.placeholder': 'Серік',
    'contactsPart.form.transaction': 'Аударым нөмірі',
    'contactsPart.form.message': 'Сіздің хабарламаңыз',
    'contactsPart.form.message.placeholder': 'Хабарлама',
    'contactsPart.form.btn.submit': 'Жіберу',
    'contactsPart.wait.title': 'Сұрақ жіберілуде',
    'contactsPart.success.title': 'Сұрақ жіберілген',
    'contactsPart.warning.title': 'Бірдеңенің жолы болмады',
    'contactsPart.warning.btn': 'Тағы бір рет қайталау',
    'contactsPart.email.subject': trId => `Кері байланыс (${trId})`,
    'contactsPart.email.message': (name, email, trId, message) => `Пайдаланушының аты:\n${name}\n\nПайдаланушының поштасы:\n${email}\n\nТранзакиця:\n${trId}\n\nХабарлама:\n${message}`,

    'footer.title1': '050000, Қазақстан, Алматы қ., Бостандық ауданы,  Нұрсұлтан Назарбаев даңғ., 193',
    'footer.text1': 'Жауапкершілігі шектеулі серіктестігі "Paycenter (Пэйцентр) <br/>БСН 190440033710',
    'footer.title2': 'Техникалық қолдау',
    'footer.text2': 'Сіздерге тәуліктің кез келген уақытында көмектесу үшін біз мұнда боламыз. Қандай да болса мәселелер туындаған жағдайда бізге хабарлаңыз',
    'footer.agreements': 'Келісімдер',
    // 'footer.agreements.offer': 'Оферта',
      
    'footer.agreements.terms': `Қосылу&#160;шарты`,
    'footer.agreements.policy': 'Құпиялылық&#160;саясаты',
    'footer.btn.feedback': 'Жазу',
    'footer.btn.address': 'Карта',

    'textInput.copy': 'Көшіріп алу',
    'textInput.insert': 'Қою',

    'backLink.back': 'Артқа',
    'backLink.toMain': 'Басты бетке',
}