import React from 'react';
import {BehaviorSubject} from 'rxjs';

const startStep = window.location.href.split('?trId=')[1] ? 0 : -1;

const transferMode$ = new BehaviorSubject(startStep);

const setTransferMode = mode => {
    mode > -1 && setTimeout(() => window.scrollTo({top: 0}), 10);
    transferMode$.next(mode);
    window.history.pushState({}, null, '/');
};

export default function (WrappedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props);

            this.state = {mode: 0};
        }

        componentDidMount() {
            this.subscription = transferMode$.subscribe(mode =>
                this.setState({mode})
            );
        }

        componentWillUnmount() {
            this.subscription.unsubscribe();
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    transferMode={this.state.mode}
                    setTransferMode={setTransferMode}
                />
            );
        }
    };
}