import React from 'react';
import {Paper} from "@material-ui/core";
import ReactHtmlParser from 'react-html-parser';
import WithTranslator from '../../providers/WithTranslator';

import './AboutPart.scss';
import { ReactComponent as Icon1 } from '../../resources/about-background1.svg';
import { ReactComponent as Icon2 } from '../../resources/about-background2.svg';

class AboutPart extends React.Component {
    render() {
        const translator = this.props.translator;
        return (
            <div className='about-part'>
                <span className='h2 _primary'>{translator('aboutPart.title1')}</span>
                <div className='container' id='about1Part'>
                    <div className='background'>
                        <Icon1/>
                    </div>
                    <Paper className='item'>
                        <span className='h3 _primary'>{translator(`aboutPart.card1.title`)}</span>
                        {[1, 2, 3, 4, 5, 6].map((i) => 
                            <span key={i} className='list-item'>
                                {ReactHtmlParser(translator(`aboutPart.card1.text${i}`))}
                            </span>
                        )}
                    </Paper>
                    <Paper className='item'>
                        <span className='h3 _primary'>{translator(`aboutPart.card2.title`)}</span>
                        {[1, 2, 3, 4, 5, 6, 7].map((i) => 
                            <span key={i} className='list-item'>
                                {ReactHtmlParser(translator(`aboutPart.card2.text${i}`))}
                            </span>
                        )}
                        
                    </Paper>
                </div>

                <span className='h2 _primary'>{translator('aboutPart.title2')}</span>
                <div className='container' id='about2Part'>
                    <Paper className='item second'>
                        <div className='item-content'>
                            <span className='h3 _primary'>{translator(`aboutPart.card3.title`)}</span>
                            {ReactHtmlParser(translator(`aboutPart.card3.text`))}
                        </div>
                    </Paper>
                    <div className='background'>
                        <Icon2/>
                    </div>
                    <Paper className='item second'>
                        <div className='item-content'>
                            <span className='h3 _primary'>{translator(`aboutPart.card4.title`)}</span>
                            {[1, 2, 3, 4].map((i) => 
                                <span key={i} className='list-item'>
                                    {ReactHtmlParser(translator(`aboutPart.card4.text${i}`))}
                                </span>
                            )}
                        </div>
                    </Paper>
                </div>
            </div>
        )
    }
}

export default WithTranslator(AboutPart);