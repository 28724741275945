import React from 'react';

import Header from './components/header/Header';
import Main from './components/mainPart/MainPart';
import AboutPart from './components/aboutPart/AboutPart';
import CommissionPart from './components/commissionPart/CommissionPart';
import QuestionsPart from './components/advantagesPart/AdvantagesPart';
import Footer from './components/footer/Footer';
import WithTransferMode from "./providers/WithTransferMode";
import TransferPart from "./components/transferPart/TransferPart";
import ContactsPart from "./components/contactsPart/ContactsPart";

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            feedback: false,
        }
    }

    openFeedback = () => this.setState({feedback: true});

    closeFeedback = () => this.setState({feedback: false});

    render() {
        return (
            <div className={`app-container ${this.props.transferMode > -1 ? 'full-mode' : ''}`}>
                <Header/>
                <div className={`app ${this.props.transferMode > -1 ? '_hidden' : ''}`}>
                    <Main/>
                    <QuestionsPart/>
                    <AboutPart/>
                    <CommissionPart/>
                </div>
                {(this.props.transferMode === 0 || this.props.transferMode === 1) && <TransferPart/>}
                <ContactsPart open={this.state.feedback} closeFeedback={this.closeFeedback}/>
                <Footer openFeedback={this.openFeedback}/>
            </div>
        )
    }
}

export default WithTransferMode(App);
