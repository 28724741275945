export default {
    'header.link.main': 'Перевод',
    'header.link.advantagesPart': 'Преимущества',
    'header.link.about1': 'Отправителю',
    'header.link.about2': 'Получателю',
    'header.link.commission': 'Условия',
    'header.lang.ru': 'RU',
    'header.lang.kz': 'KZ',

    'mainPart.title': 'Переводы денег<br/>с карты на карту',
    'mainPart.btn.makeTransfer': 'Отправить перевод',
    'mainPart.btn.receiveTransfer': 'Получить перевод',

    'aboutPart.title1': 'Как провести перевод',
    'aboutPart.title2': 'Я получатель, как мне получить деньги?',
    'aboutPart.card1.title': 'Если я знаю карту получателя',
    'aboutPart.card2.title': 'Если я не знаю карту получателя',
    'aboutPart.card3.title': 'Если отправитель ввел данные моей карты',
    'aboutPart.card4.title': 'Если отправитель выслал мне код перевода',
    'aboutPart.card1.text1': "Ввести сумму перевода",
    'aboutPart.card1.text2': "Ввести данные своей карты",
    'aboutPart.card1.text3': "Согласиться с условиями оферты",
    'aboutPart.card1.text4': "Нажать «Продолжить»",
    'aboutPart.card1.text5': "Ввести номер и дату окончания действия карты получателя денег",
    'aboutPart.card1.text6': "Готово!",
    'aboutPart.card2.text1': "Ввести сумму перевода",
    'aboutPart.card2.text2': "Ввести данные своей карты",
    'aboutPart.card2.text3': "Согласиться с условиями оферты",
    'aboutPart.card2.text4': "Нажать «Продолжить»",
    'aboutPart.card2.text5': "Получить код перевода",
    'aboutPart.card2.text6': "Скопировать код перевода и отправить получателю",
    'aboutPart.card2.text7': "Готово!",
    'aboutPart.card3.text': 'Нужно лишь подождать, пока пройдет перевод! <br/> Срок зачисления средств на счет карты получателя зависит от банка, выпустившего карту получателя, и может составлять от нескольких минут до нескольких дней',
    'aboutPart.card4.text1': "Перейти на вкладку получить перевод",
    'aboutPart.card4.text2': "Вставить код перевода",
    'aboutPart.card4.text3': "Нажать «Получить»",
    'aboutPart.card4.text4': "Готово!",

    'transferPart.title': 'Перевод',
    'transferPart.form.card1.title': 'Карта отправителя',
    'transferPart.form.card2.title': 'Карта получателя',
    'transferPart.form.amount.title': 'Сумма',
    'transferPart.menu.btn.makeTransfer': 'Сделать перевод',
    'transferPart.menu.btn.receiveTransfer': 'Получить перевод',
    'transferPart.form.currency.rub': '₽ Рубль',
    'transferPart.form.currency.usd': '$ Доллар',
    'transferPart.form.currency.eur': '€ Евро',
    'transferPart.form.currency.kzt': '₸ Тенге',
    'transferPart.form.amount.placeholder': 'Сумма перевода',
    'transferPart.form.currency.placeholder': 'Валюта',
    'transferPart.form.card1.placeholder': 'Номер карты отправителя',
    'transferPart.form.card2.placeholder': 'Номер карты получателя',
    'transferPart.form.owner.placeholder': 'Держатель карты',
    'transferPart.form.date.placeholder': 'Месяц/Год',
    'transferPart.form.cvv.placeholder': 'CVV',
    'transferPart.form.commission1': 'Сумма перевода ',
    'transferPart.form.commission2': 'Комиссия составит ',
    'transferPart.form.commission3': 'ИТОГО ',
    'transferPart.form.agree1': 'Я согласен с условиями',
    'transferPart.form.agree2': 'Договора присоединения',
    'transferPart.form.code': 'Получить код перевода',
    'transferPart.form.code.description': 'Что это?',
    'transferPart.form.code.tooltip': 'Если вы не знаете данные карты получателя, вы можете получить код перевода. После отправления кода получателю, он введет карту, на которую получит деньги самостоятельно',
    'transferPart.form.btn1.submit': 'Продолжить',
    'transferPart.form.btn1.cancel': 'Сбросить',
    'transferPart.form.btn2.submit': 'Перевести',
    'transferPart.form.btn2.cancel': 'Назад',
    'transferPart.form.get.submit': 'Получить',
    'transferPart.result.wait.title': 'Проверка транзакции',
    'transferPart.result.wait.description': 'Обычно это занимает несколько секунд, не закрывайте и не обновляйте эту страницу',
    'transferPart.result.code.title': 'Код перевода',
    'transferPart.result.code.description': 'Скопируйте код и отправьте его получателю',
    'transferPart.result.code.submit': 'Новый перевод',
    'transferPart.result.success.title': 'Ваши средства в пути',
    'transferPart.result.success.description': 'Обработка запроса прошла успешно',
    'transferPart.result.success.submit': 'Новый перевод',
    'transferPart.result.warning.title': 'Где-то ошибка',
    'transferPart.result.warning.description': 'Запрос не прошел, но можно попробовать еще раз',
    'transferPart.result.warning.submit': 'Попробовать еще раз',

    'commissionPart.title': 'Условия',
    'commissionPart.commission': 'Комиссия:',
    'commissionPart.limit': 'Лимит:',
    'commissionPart.title11': 'Внутренние переводы',
    'commissionPart.title12': 'Между картами банков Республики Казахстан',
    'commissionPart.title21': 'Трансграничные переводы',
    'commissionPart.title22': 'с карты РФ на иностранные',
    'commissionPart.commission1': '2,5%',
    'commissionPart.commission2': '2%',
    'commissionPart.limit1': 'Минимум 50 ₸',
    'commissionPart.limit2': 'Минимум 200 ₸',
    'commissionPart.body2': "Скоро Запуск",
    'commissionPart.warning.title': 'Сроки',
    'commissionPart.warning': 'Срок зачисления средств на счет карты получателя зависит от банка, выпустившего карту получателя, и может составлять от нескольких минут до нескольких дней',

    'advantagesPart.title': 'Преимущества',
    'advantagesPart.title0': 'Без карты получателя',
    'advantagesPart.text0': 'Можно перевести деньги,<br/>не зная данных карты получателя',
    'advantagesPart.title1': 'Другие банки',
    'advantagesPart.text1': 'Пополнение средств<br/>платёжной карты другого банка',
    'advantagesPart.title2': 'Фиксированная цена',
    'advantagesPart.text2': 'Мы отображаем сумму перевода<br/>и комиссию перед отправкой',

    'contactsPart.form.title': 'Техническая поддержка',
    'contactsPart.form.email': 'Email',
    'contactsPart.form.name': 'Имя',
    'contactsPart.form.name.placeholder': 'Иван',
    'contactsPart.form.transaction': 'Номер перевода',
    'contactsPart.form.message': 'Ваше сообщение',
    'contactsPart.form.message.placeholder': 'Сообщение',
    'contactsPart.form.btn.submit': 'Отправить',
    'contactsPart.wait.title': 'Вопрос отправляется',
    'contactsPart.success.title': 'Вопрос отправлен',
    'contactsPart.warning.title': 'Что-то пошло не так',
    'contactsPart.warning.btn': 'Попробовать еще раз',
    'contactsPart.email.subject': trId => `Обратная связь (${trId})`,
    'contactsPart.email.message': (name, email, trId, message) => `Имя пользователя:\n${name}\n\nПочта пользователя:\n${email}\n\nТранзакиця:\n${trId}\n\nСообщение:\n${message}`,

    'footer.title1': '050000, Казахстан, г. Алматы, Бостандыкский район, пр. Нурсултан Назарбаев, 193',
    'footer.text1': 'Товарищество с ограниченной ответственностью "Paycenter (Пэйцентр) <br/>БИН 190440033710',
    'footer.title2': 'Техническая поддержка',
    'footer.text2': 'Мы здесь, чтобы помочь в любое время суток. В случае каких-либо вопросов, пожалуйста, свяжитесь с нами',
    'footer.agreements': 'Соглашения',
    // 'footer.agreements.offer': 'Оферта',
      
    'footer.agreements.terms': `Договор&#160;присоединения`,
    'footer.agreements.policy': 'Политика&#160;конфиденциальности',
    'footer.btn.feedback': 'Написать',
    'footer.btn.address': 'Карта',

    'textInput.copy': 'Скопировать',
    'textInput.insert': 'Вставить',

    'backLink.back': 'Назад',
    'backLink.toMain': 'На главную',
}