import React from 'react';
import TextInput from '../textInpit/TextInput';
import WithTranslator from '../../providers/WithTranslator';
import FieldsHelper from './FieldsHelper';
import {Paper} from "@material-ui/core";

class TransferCardForm extends React.Component {
    constructor(props) {
        super(props);

        this.FHelper = new FieldsHelper(this.props.storage);
    }

    render() {
        const {translator, storage} = this.props;
        return (
            <Paper className='card'>
                <span className='h3'>{translator('transferPart.form.card1.title')}</span>
                <TextInput label={translator('transferPart.form.card1.placeholder')}
                           className='margin-bottom'
                           placeholder='xxxx xxxx xxxx xxxx'
                           error={storage.state.errors.cardError}
                           value={storage.state.fields.card}
                           onChange={this.FHelper.onCardChange}
                           onBlur={this.FHelper.onCardBlur}/>
                <div className='inline-fields margin-bottom'>
                    <TextInput label={translator('transferPart.form.date.placeholder')}
                               placeholder='11/25'
                               error={storage.state.errors.dateError}
                               value={storage.state.fields.date}
                               onBlur={this.FHelper.onDateBlur}
                               onChange={this.FHelper.onDateChange}/>
                    <TextInput label={translator('transferPart.form.cvv.placeholder')}
                               placeholder='123'
                               error={storage.state.errors.cvvError} type='password' autoComplete='off'
                               value={storage.state.fields.cvv}
                               onChange={this.FHelper.onCvvChange}/>
                </div>
                <TextInput label={translator('transferPart.form.owner.placeholder')}
                           placeholder='IVAN IVANOV'
                           error={storage.state.errors.ownerError}
                           value={storage.state.fields.owner}
                           onChange={this.FHelper.onOwnerChange}/>
            </Paper>
        )
    }
}

export default WithTranslator(TransferCardForm);
