import React from 'react';
import {Drawer, Fab} from '@material-ui/core';
import LanguageControl from './LanguageControl';
import WithTranslator from '../../providers/WithTranslator';

import './Header.scss';
import {ReactComponent as IconCancel} from "../../resources/icons/icon-cancel.svg";
import {ReactComponent as Menu} from "../../resources/icons/icon-menu.svg";

class HeaderMobileMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileMenuAnchor: null
        }
    }

    goTo = element => {
        this.onClose();
        setTimeout(() => this.props.goTo(element));
    }

    onClose = () => this.setState({mobileMenuAnchor: null})

    render() {
        const {translator} = this.props,
            open = !!this.state.mobileMenuAnchor;

        return (
            <>
                <div className='header-short'>
                    <Fab
                        onClick={e => this.setState({mobileMenuAnchor: e.currentTarget})}
                        disableRipple>
                        <Menu/>
                    </Fab>
                    <LanguageControl/>
                </div>
                <Drawer anchor='left' open={open} onClose={this.onClose} className='header-mobile-menu'>
                    <Fab onClick={this.onClose} className='close-btn' disableRipple>
                        <IconCancel/>
                    </Fab>
                    <div className='menu'>
                        <a className={this.props.mainPartVisible ? '_active' : ''}
                           onClick={() => this.goTo(this.props.mainPart)}>
                            {translator('header.link.main')}
                        </a>
                        <a className={this.props.advantagesPartVisible ? '_active' : ''}
                           onClick={() => this.goTo(this.props.advantagesPart)}>
                            {translator('header.link.advantagesPart')}
                        </a>
                        <a className={this.props.about1PartVisible ? '_active' : ''}
                           onClick={() => this.goTo(this.props.about1Part)}>
                            {translator('header.link.about1')}
                        </a>
                        <a className={this.props.about2PartVisible ? '_active' : ''}
                           onClick={() => this.goTo(this.props.about2Part)}>
                            {translator('header.link.about2')}
                        </a>
                        <a className={this.props.commissionPartVisible ? '_active' : ''}
                           onClick={() => this.goTo(this.props.commissionPart)}>
                            {translator('header.link.commission')}
                        </a>
                    </div>
                </Drawer>
            </>
        )
    }
}

export default WithTranslator(HeaderMobileMenu);
