import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {Button} from "@material-ui/core";
import WithTranslator from '../../providers/WithTranslator';
import WithTransferMode from '../../providers/WithTransferMode';

import './MainPart.scss';
import background from '../../resources/main-background.png';

class MainPart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {ready: false};
    }

    componentDidMount() {
        setTimeout(() => this.setState({ready: true}), 1000);
    }

    render() {
        const translator = this.props.translator;
        return (
            <div className='main-part' id='mainPart'>
                <div className='content'>
                    <span className='title'>{ReactHtmlParser(translator('mainPart.title'))}</span>
                    <span className='text'></span>

                    <div className='menu'>
                        <Button onClick={() => this.props.setTransferMode(0)}
                                className='primary'>
                            {translator('transferPart.menu.btn.makeTransfer')}
                        </Button>
                        <Button onClick={() => this.props.setTransferMode(1)}
                                className='secondary'>
                            {translator('transferPart.menu.btn.receiveTransfer')}
                        </Button>
                    </div>
                </div>

                <img src={background} alt=''/>
            </div>
        )
    }

}

export default WithTransferMode(WithTranslator(MainPart));