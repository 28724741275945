import React from 'react';
import {Button, Paper} from '@material-ui/core';
import TextInput from '../textInpit/TextInput';
import WithTranslator from '../../providers/WithTranslator';
import FieldsHelper from './FieldsHelper';
import FValidator from './FieldsValidator';
import TransferResultStep from './TransferResultStep';
import apiService from '../../services/apiService';
import {RESULT_STATUS as STATUS} from '../../constants';
import {ReactComponent as IconBack} from "../../resources/icons/icon-back.svg";
import WithTransferMode from "../../providers/WithTransferMode";
import TransferIcons from "./TransferIcons";

const defaultFields = {
    code: '',
    card: '',
    date: ''
};

class TransferGet extends React.Component {
    constructor(props) {
        super(props);

        this.FHelper = new FieldsHelper(this);
        this.state = {
            status: STATUS.FORM,
            fields: defaultFields,
            errors: {},
        };
    }

    onSubmit = () => {
        const errors = {
            codeError: !this.state.fields.code,
            cardError: !FValidator.isCardValid(this.state.fields.card),
            dateError: !FValidator.isDateValid(this.state.fields.date),
        };
        if (Object.values(errors).some(i => !!i)) {
            this.setState({errors: {...errors}});
        } else {
            this.send();
        }
    }

    send = () => {
        const [month, year] = this.state.fields.date.split('/');
        this.setState({status: STATUS.WAIT}, () => window.scrollTo({top: 0}));
        const data = {
            'to': {
                'cardNumber': this.state.fields.card.replaceAll(' ', ''),
                'expMonth': +month,
                'expYear': +year,
            },
            'trId': this.state.fields.code
        }
        apiService.continueTransfer(data).then(state => {
            state.status === STATUS.CODE && (state.status = STATUS.ERROR);
            this.setState({...state}, () => window.scrollTo({top: 0}))
        });
    }

    onStart = () => {
        const state = this.state.status === STATUS.ERROR
            ? {status: STATUS.FORM}
            : {status: STATUS.FORM, fields: {...defaultFields}};
        this.setState(state, () => window.scrollTo({top: 0}));
        window.history.pushState({}, null, '/');
    }

    render() {
        const translator = this.props.translator;
        return (
            <div className={`container`}>
                <button className='back-link' onClick={() => this.props.setTransferMode(-1)}>
                    <IconBack/>
                    <span>{translator('backLink.toMain')}</span>
                </button>
                {this.state.status === STATUS.FORM
                    ?
                    <div className='transfer'>
                        <div>
                            <span className='header'>
                                {translator('transferPart.menu.btn.receiveTransfer')}
                            </span>

                            <TransferIcons/>

                            <div className='cards'>
                                <Paper className='card'>
                                    <span className='h3 margin-bottom'>
                                        {translator('transferPart.result.code.title')}
                                    </span>

                                    <TextInput label={translator('transferPart.result.code.title')}
                                               withInsert
                                               placeholder='1111111'
                                               value={this.state.fields.code}
                                               error={this.state.errors.codeError}
                                               onChange={this.FHelper.onCodeChange}/>

                                </Paper>
                                <Paper className='card'>
                                    <span className='h3'>
                                        {translator('transferPart.form.card2.title')}
                                    </span>

                                    <div className='inline-fields margin-bottom'>
                                        <TextInput label={translator('transferPart.form.card2.placeholder')}
                                                   className='_big'
                                                   placeholder='xxxx xxxx xxxx xxxx'
                                                   error={this.state.errors.cardError}
                                                   value={this.state.fields.card}
                                                   onChange={this.FHelper.onCardChange}
                                                   onBlur={this.FHelper.onCardBlur}/>
                                        <TextInput label={translator('transferPart.form.date.placeholder')}
                                                   className='_small'
                                                   placeholder='11/25'
                                                   error={this.state.errors.dateError}
                                                   value={this.state.fields.date}
                                                   onBlur={this.FHelper.onDateBlur}
                                                   onChange={this.FHelper.onDateChange}/>
                                    </div>
                                </Paper>
                            </div>
                        </div>

                        <Button className='submit primary' onClick={this.onSubmit}
                                          disabled={
                                              !Object.entries(this.state.fields).every(([k, v]) => !!v)
                                              || Object.values(this.state.errors).some(e => !!e)
                                          }>
                            {translator('transferPart.form.get.submit')}
                        </Button>
                    </div>
                    :
                    <TransferResultStep status={this.state.status} onSubmit={this.onStart}
                                        trId={this.state.trId}/>
                }
            </div>
        )
    }

}

export default WithTransferMode(WithTranslator(TransferGet));
