import React from 'react';
import {Button} from '@material-ui/core';
import WithTranslator from '../../providers/WithTranslator';

const LanguageControl = function ({translator, language, setLanguage}) {
    return (
        <div>
            <Button className={`lang-button ${language === 'ru' ? '_active' : ''}`}
                    onClick={() => setLanguage('ru')}>
                {translator(`header.lang.ru`)}
            </Button>
            <Button className={`lang-button ${language === 'kz' ? '_active' : ''}`}
                    onClick={() => setLanguage('kz')}>
                {translator(`header.lang.kz`)}
            </Button>
        </div>
    )
}

export default WithTranslator(LanguageControl);