import React from 'react';
import {Paper} from "@material-ui/core";
import WithTranslator from '../../providers/WithTranslator';

import './CommissionPart.scss';
import { ReactComponent as Icon } from '../../resources/commission.svg';

class CommissionPart extends React.Component {
    render() {
        const translator = this.props.translator;
        return (
            <div className='commission-part' id='commissionPart'>
                <span className='h2 _primary'>{translator('commissionPart.title')}</span>
                <div className='container' id='commissionPart'>
                    <Paper className='item'>
                        <span className='header'>{translator('commissionPart.title12')}</span>
                        <span className='h3 _primary'>{translator('commissionPart.title11')}</span>
                        <div className='mr-b'>
                            <span className='mr-r'>{translator('commissionPart.commission')}</span>
                            {translator('commissionPart.commission1')}
                        </div>
                        <div>
                            <span className='mr-r'>{translator('commissionPart.limit')}</span>
                            {translator('commissionPart.limit1')}
                        </div>
                        <Icon/>
                    </Paper>
                    <Paper className='item'>
                        <span className='header'>{translator('commissionPart.title22')}</span>
                        <span className='h3 _primary'>{translator('commissionPart.title21')}</span>
                      
                      <div className='text-light'>
                          {translator('commissionPart.body2')}
                      </div>
                    </Paper>
                    <Paper className='item'>
                        <span className='h3 _primary'>{translator('commissionPart.warning.title')}</span>
                        <span className='short'>{translator('commissionPart.warning')}</span>
                    </Paper>
                </div>
            </div>
        )
    }
}

export default WithTranslator(CommissionPart);