import React from 'react';

import cardLogo1 from '../../resources/icons/cardLogo1.svg';
import cardLogo2 from '../../resources/icons/cardLogo2.svg';
import cardLogo3 from '../../resources/icons/cardLogo3.svg';
import cardLogo4 from '../../resources/icons/cardLogo4.svg';
import cardLogo5 from '../../resources/icons/cardLogo5.svg';
import cardLogo6 from '../../resources/icons/cardLogo6.svg';
import cardLogo7 from '../../resources/icons/cardLogo7.svg';

export default function () {
    return (
        <div className='icons'>
            <img src={cardLogo1} alt='logo'/>
            <img src={cardLogo2} alt='logo'/>
            <img src={cardLogo3} alt='logo'/>
            <img src={cardLogo4} alt='logo'/>
            <img src={cardLogo5} alt='logo'/>
            <img src={cardLogo6} alt='logo'/>
            <img src={cardLogo7} alt='logo'/>
        </div>
    );
}